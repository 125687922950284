import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { motion, useAnimation } from "framer-motion";
import { FaGithub, FaDocker, FaJenkins, FaNpm, FaGit } from "react-icons/fa";
import { SiJira, SiTrello, SiAsana, SiBasecamp, SiClickup, SiNotion, SiPostman, SiGooglecloud, SiTableau, SiSalesforce } from 'react-icons/si';
import { CgMonday } from "react-icons/cg";
import { BsMicrosoftTeams } from "react-icons/bs";
import { VscVscode } from "react-icons/vsc";

const MyTabs = () => {
  const [value, setValue] = useState(0);

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      scale: 1,
      transition: { delay: 0.2, duration: 0.5, type: "spring", stiffness: 100 },
    });
  }, [controls]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1 }}
      animate={controls}
    >
      <Box
        sx={{
          width: '90vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 600 }} className='pb-5'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs example"
            centered
            sx={{
              // background: 'rgb(17, 94, 89, 1)', 
              borderRadius: 2, fontWeight: 'bold',
              '& .MuiTabs-indicator': { backgroundColor: 'white' },
              '.MuiTab-root': {
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'system-ui',
                fontSize: '0.95rem',
                '&.Mui-selected': {
                  color: 'white',
                },
              },
            }}
            variant="fullWidth"
          >
            <Tab label="Future Software Engineers" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label="Future Project Managers" id="tab-1" aria-controls="tabpanel-1" />
          </Tabs>
        </Box>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={controls}
        >
          <Box
            display={
              value === 0 ? 'block' : 'none'
            }
            className='text-gray-300 font-alliance font-bold tracking-tight text-base sm:w-[50vw] sm:max-w-[50vw] md:w-[40vw] md:max-w-[40vw]'
          >
            We provide hands-on experience with <span className='text-[rgb(17,94,89)] font-bold uppercase'>industry tools</span> through <span className='font-bold text-[rgb(17,94,89)] uppercase'>impactful</span> non-profit projects, helping you build <span className='text-[rgb(17,94,89)] font-bold uppercase'>real-world technical skills</span> and enhance your portfolios.
            <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-evenly'} padding={2}>
              <FaGit className='text-white text-4xl m-2' />
              <FaGithub className='text-white text-4xl m-2' />
              <SiJira className='text-white text-4xl m-2' />
              <VscVscode className='text-white text-4xl m-2' />
              <BsMicrosoftTeams className='text-white text-4xl m-2' />
              <FaNpm className='text-white text-4xl m-2' />
              <FaDocker className='text-white text-4xl m-2' />
              <FaJenkins className='text-white text-4xl m-2' />
              <SiPostman className='text-white text-4xl m-2' />
              <SiGooglecloud className='text-white text-4xl m-2' />
            </Box>
          </Box>
        
        <Box
          display={
            value === 1 ? 'block' : 'none'
          }
          className='text-gray-300 font-alliance font-bold tracking-tight text-base sm:w-[50vw] sm:max-w-[50vw] md:w-[40vw] md:max-w-[40vw]'
        >
          Work with <span className='text-[rgb(17,94,89)] font-bold uppercase'>leading project management tools</span> to drive progress on important non-profit projects, developing your <span className='text-[rgb(17,94,89)] font-bold uppercase'>project planning</span> and <span className='text-[rgb(17,94,89)] font-bold uppercase'>team coordination</span> skills for your future careers.
          <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-evenly'} padding={2}>
            <SiJira className='text-white text-4xl m-2' />
            <BsMicrosoftTeams className='text-white text-4xl m-2' />
            <SiTrello className='text-white text-4xl m-2' />
            <CgMonday className='text-white text-4xl m-2' />
            <SiAsana className='text-white text-4xl m-2' />
            <SiSalesforce className='text-white text-4xl m-2' />
            <SiNotion className='text-white text-4xl m-2' />
            <SiBasecamp className='text-white text-4xl m-2' />
            <SiClickup className='text-white text-4xl m-2' />
            <SiTableau className='text-white text-4xl m-2' />
          </Box>
        </Box>
        </motion.div>
      </Box>
    </motion.div>
  );
};

export default MyTabs;