import React from 'react';

const smalltext: React.FC = () => {
    return (
        <div>
            <div className="text-center">
                <p className="text-teal-800 font-alliance text-xl lowercase mt-10 italic font-[350]">"Designed with students in mind"</p>
            </div>
        </div>
    );
}

export default smalltext;