import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import allRoutes from './routes/routes';

const router = createBrowserRouter(allRoutes);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
