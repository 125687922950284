import { useRef, useEffect, useState } from "react";
// import { FiArrowRight, FiTag, FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { FiArrowRight } from "react-icons/fi";
import { GiPartyPopper } from "react-icons/gi";
import { motion, useAnimation } from "framer-motion";
import { Box } from '@mui/material';
import { useCustomSnackbar } from "../../utils";
import axios from 'axios';

const GlassContainer: React.FC = () => {
    return (
        <div className="relative inset-0 flex items-center justify-center z-30 px-4 mt-5">
            <div className="flex flex-col items-center">
                <AnimatedRoundedTag />
                <AnimatedHeader />
                <AnimatedCard />
                {/* <div className="flex gap-4">
                    <SocialMediaIcon icon={<FiFacebook />} delay={0.2} />
                    <SocialMediaIcon icon={<FiTwitter />} delay={0.4} />
                    <SocialMediaIcon icon={<FiInstagram />} delay={0.6} />
                </div> */}
            </div>
        </div>
    );
};

const AnimatedRoundedTag = () => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start({
            opacity: 1,
            scale: 1,
            transition: { delay: 0.2, duration: 0.5, type: "spring", stiffness: 100 },
        });
    }, [controls]);

    return (
        <Box display={'flex'} flexDirection={'row'} gap={2}>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={controls}
                className="bg-white backdrop-filter backdrop-blur-md border border-gray-500 rounded-full px-4 py-2 flex items-center gap-2 shadow-md mb-6"
            >
                <GiPartyPopper className="text-teal-800" />
                <span className="text-teal-800 text-sm font-medium ">5+ Clients</span>
            </motion.div>
        </Box>
    );
};

const AnimatedHeader = () => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start({
            opacity: 1,
            scale: 1,
            transition: { delay: 0.4, duration: 0.5, type: "spring", stiffness: 100 },
        });
    }, [controls]);

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={controls}
            className="relative flex items-center justify-center w-full mb-8"
        >
            {/* <span className="absolute mx-auto py-4 flex border w-fit bg-gradient-to-r blur-xl from-gray-300 via-gray-200 to-gray-100 bg-clip-text text-3xl box-content font-extrabold text-transparent text-center select-none tracking-tight text-teal-800">
                Coming Soon!
            </span> */}
            <h1 className="relative top-0 w-fit h-auto py-4 justify-center flex bg-gradient-to-r items-center from-gray-300 via-gray-200 to-gray-100 bg-clip-text text-3xl font-extrabold text-transparent text-center select-auto tracking-tight">
                Launching Soon!
            </h1>
        </motion.div>
    );
};

const AnimatedCard = () => {
    const controls = useAnimation();
    const inputRef = useRef(null);
    const showSnackbar = useCustomSnackbar();

    const [email, setEmail] = useState('');

    useEffect(() => {
        controls.start({
            opacity: 1,
            scale: 1,
            transition: { delay: 0.6, duration: 0.5, type: "spring", stiffness: 100 },
        });
    }, [controls]);

    const validateEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const onSubmit = (e: any) => {
        e.stopPropagation()

        if (!email) {
            showSnackbar('Please enter a valid email.', { variant: 'error' });
            return;
        }

        if (validateEmail(email)) {
            axios.post('/api/subscribe', { email })
                .then(() => {
                    setEmail('');
                    showSnackbar('Thank you for joining the waitlist!', { variant: 'success' });
                })
                .catch((err) => {
                    showSnackbar('An error occurred. Please try again later.', { variant: 'error' });
                });
        } else {
            showSnackbar('Please enter a valid email.', { variant: 'error' });
        }

    }

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={controls}
            className="bg-teal-800 bg-opacity-50 backdrop-filter backdrop-blur-lg border border-teal-800 rounded-3xl p-8 py-12 shadow-lg max-w-2xl w-full mb-6 pb-5"
        >
            <h2 className="text-white text-xl font-semibold text-center">Secure Your Spot by Joining the Waitlist Today.</h2>
            <p className="text-white mt-4 text-center sm:px-16 text-sm lg:text-base">
                Join 100+ Students Ready to Launch Their Careers
            </p>
            <div className="max-w-sm mx-auto pb-5">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    onClick={() => {
                        if (inputRef.current) (inputRef.current as HTMLInputElement).focus();
                    }}
                    className="relative flex w-full max-w-md items-center gap-2 rounded-full border border-white/20 bg-black py-1.5 pl-6 pr-1.5 mt-6 shadow-2xl"
                >
                    <input
                        ref={inputRef}
                        type="email"
                        placeholder="Enter your email"
                        className="w-full bg-transparent text-sm text-white placeholder-white/80"
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ border: 'none', outline: 'none' }}
                    />
                    <button
                        onClick={(e) => onSubmit(e)}
                        type="submit"
                        className="group flex shrink-0 items-center gap-1.5 rounded-full bg-gray-200 px-4 py-3 text-sm font-medium text-black transition-transform active:scale-[0.985]"
                    >
                        <span>Join Waitlist</span>
                        <FiArrowRight className="-mr-4 opacity-0 transition-all group-hover:-mr-0 group-hover:opacity-100 group-active:-rotate-45" />
                    </button>
                </form>
            </div>

        </motion.div>
    );
};

// interface SocialMediaIconProps {
//     icon: React.ReactNode;  // or specify a more precise type if needed
//     delay: number;
// }

// const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ icon, delay }) => {
//     const controls = useAnimation();

//     useEffect(() => {
//         controls.start({
//             opacity: 1,
//             rotate: 0,
//             scale: 1,
//             transition: { delay, duration: 0.5, type: "spring", stiffness: 100 },
//         });
//     }, [controls, delay]);

//     return (
//         <motion.div
//             initial={{ opacity: 0, rotate: -45, scale: 1 }}
//             animate={controls}
//             whileHover={{ scale: 1.1, boxShadow: "0px 0px 8px rgba(255, 255, 255, 0.8)" }}
//             className="bg-teal-800 bg-opacity-30 backdrop-filter backdrop-blur-md border border-gray-500 rounded-lg p-3 shadow-md flex items-center justify-center text-white text-xl"
//         >
//             {icon}
//         </motion.div>
//     );
// };

export default GlassContainer;
