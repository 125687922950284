import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useSnackbar } from 'notistack';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (message: string, options = {}) => {
    enqueueSnackbar(message, {
      ...options,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 3000,
    });
  };

  return showSnackbar;
};