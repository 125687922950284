import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";

const SubHeader = () => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start({
            opacity: 1,
            scale: 1,
            transition: { delay: 0.2, duration: 0.5, type: "spring", stiffness: 100 },
        });
    }, [controls]);
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={controls}
        >
            <div className='pb-10 px-4 sm:px-6'>
                <h1 className="text-xl font-bold tracking-tight sm:text-2xl">
                    <span className="relative whitespace-normal text-gray-300 font-alliance">
                        Transform Your Passion into Professional Experience with Internly
                    </span>
                </h1>

            </div>
        </motion.div>
    );
}

export default SubHeader;