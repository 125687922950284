import HeaderWL from '../../components/waitlist/header';
import GlassContainer from '../../components/waitlist/join';
import SubHeader from '../../components/waitlist/subheader';
import Tabs from '../../components/waitlist/tabs';
import React from 'react';

const Home: React.FC = () => {
  return (
    <div className="App">
    <header className='relative App-header m-0 p-0 pb-0'>
      <HeaderWL />
      <SubHeader />
      <Tabs />
      <GlassContainer />
    </header>
  </div>
  );
};

export default Home;
