import React from 'react';
import NotFound from '../../pages/404/404';
import { RouteObject } from 'react-router-dom';

const notFoundRoutes: RouteObject[] = [
  {
    path: "*",
    element: <NotFound />,
  },
];

export default notFoundRoutes;
