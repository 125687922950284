import React from 'react';
import { RouteObject } from 'react-router-dom';
import Home from '../../pages/Home/home';

const homeRoutes: RouteObject[] = [
    {
      path: "/",
      element: <Home />,
    },
  ];
  
  export default homeRoutes;